<template>
    <v-card-text>
        <v-checkbox v-model="checked" class="mb-n4 mt-n4" :rules="[v => (v || !required)]">
            <template v-slot:label>
                <p class="mb-0">{{ label }}</p>
                <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" v-bind="attrs" v-on="on">
                            <v-icon>fa fa-circle-info</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-img :src="image_url"></v-img>
                    </v-card>
                </v-dialog>
            </template>
        </v-checkbox>
    </v-card-text>
</template>

<script>
export default {
    name: "AppCheckboxWithButton",
    props: {
        label: {
            type: String,
            required: true,
        },
        image_url: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            dialog: false,
            checked: false,
        };
    },
    mounted() {
        this.checked = this.value;
    },
    watch: {
        checked(newValue) {
            this.$emit("input", newValue);
        },
    },
};
</script>
