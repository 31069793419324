<template>
    <v-card :class="['comment-card', 'mt-3', border(comment.user.uid), margin(comment.user.uid)]" width="600px" :key="comment.uid">
        <v-card-text>
            <p  v-if="!isMe(comment.user.uid)" class="font-weight-bold username mb-1">{{ comment.user.name }}</p>
            <div class="d-flex flex-row">
                <div class="d-flex flex-column width-100">
                    <div class="width-100" v-if="comment.comment !== ''">
                        <span class="font-weight-bold black--text multiple-lines" v-html="comment.comment"></span>
                    </div>

                    <div v-if="comment.files && comment.files.pdfs" class="grey--text font-14 mt-2 mb-0">
                        <span v-for="(pdf, index) in comment.files.pdfs" :key="index">
                            <v-btn
                                :href="pdfUrl(pdf)"
                                target="_blank"
                                color="primary"
                                class="mr-5"
                                small
                                rounded
                            >
                                <v-icon left dark>
                                    mdi-paperclip
                                </v-icon>
                                Document {{index + 1}}
                            </v-btn>
                        </span>
                    </div>

                    <ImagesContainer :files="comment.files" />
                </div>
            </div>
            <div class="mr-2 comment-date-container text-right">
                <span class="color-gray-darker font-italic font-12">{{ defineDate(comment.date_change) }}</span>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from "moment";
import ImagesContainer from "@/components/ImagesContainer.vue";

export default {
    name: "CommentCard",
    components: {
        ImagesContainer,
    },
    props: {
        comment: Object,
    },
    methods: {
        defineDate(date) {
            moment.locale(this.language);
            let fromNow = moment(date).fromNow();
            return fromNow[0].toUpperCase() + fromNow.slice(1);
        },
        isMe(uid) {
            return uid === atob(this.$store.getters["authentication/user_uid"])
        },
        border(uid) {
            return this.isMe(uid) ? 'border-right-primary' : 'border-left-primary'
        },
        margin(uid) {
            return this.isMe(uid) ? 'ml-100px' : 'mr-100px'
        },
        pdfUrl(pdf) {
            return process.env.VUE_APP_BASE_URL + '/doc/pdf/' + pdf;
        }
    },
};
</script>

<style scoped>
.username {
    font-size: 1.2em;
}
</style>