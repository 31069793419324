<template>
    <p class="mt-5 mb-2 text-center color-gray-darker">
        {{ $tc("maintenance_documents.document_number", this.$store.getters["documents/get"].length) }}
    </p>
</template>

<script>
export default {
    name: "RecapDocumentsNumber",
};
</script>
