<template>
    <div v-if="images.length !== 0" class="image-preview-container">
        <div class="image-container" v-for="(image, index) in images" :key="index">
            <v-img class="image-preview" :src="imageUrl(image)" />
            <v-btn icon class="image-button" @click="removeImage(index)">
                <v-icon color="">mdi-close-circle</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImagePreview',
    props: {
        images: Array,
    },
    methods: {
        removeImage(index) {
            this.images.splice(index, 1);
        },
        imageUrl(image) {
            return URL.createObjectURL(image);
        },
    },
};
</script>
