<template>
    <div>
        <v-img class="mix-blend-mode-multiply" src="/img/error500.gif" max-width="90%" max-height="50%" contain alt="" />
        <p>Une erreur est survenue, merci de réessayer ultérieurement.</p>
    </div>
</template>

<script>
export default {
    name: "Error500",
};
</script>

<style scoped>
div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

img {
    max-width: 90%;
    max-height: 50%;
}

.mix-blend-mode-multiply {
    mix-blend-mode: multiply;
}
</style>
