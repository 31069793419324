<template>
    <v-card-text>
        <v-row class="px-4 pt-4" justify="space-around">
            <p class="mb-0 w_90p">{{ label }}</p>
            <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" class="mt-n1" v-bind="attrs" v-on="on">
                        <v-icon>fa fa-circle-info</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-img :src="image_url"></v-img>
                </v-card>
            </v-dialog>
        </v-row>
    </v-card-text>
</template>

<script>
export default {
    name: "VTextWithButton",
    props: {
        label: {
            type: String,
            required: true,
        },
        image_url: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
};
</script>

<style scoped>
.w_90p {
    width: 90%;
}
</style>
