export default {
    data: () => ({
        language: navigator.language,
    }),
    methods: {
        displayImageInComment(image) {
            return process.env.VUE_APP_BASE_URL + image;
        },
        openDialogImage(image) {
            this.$store.dispatch("image_preview/show", { src: encodeURI(process.env.VUE_APP_BASE_URL + image) });
        }
    }
};
