import Vue from "vue";
import Vuex from "vuex";

import authentication from "./modules/authentication";
import ajax from "./modules/ajax";
import snackbar from "./modules/snackbar";
import maintenance_list from "./modules/maintenance_list";
import maintenance from "./modules/maintenance";
import documents from "./modules/documents";
import document_preview from "./modules/document_preview";
import image_preview from "./modules/image_preview";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        authentication,
        ajax,
        snackbar,
        maintenance_list,
        maintenance,
        documents,
        document_preview,
        image_preview,
    },
});
