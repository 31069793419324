<template>
    <div class="app-container">
        <RecapDocumentsNumber />
        <div class="app-container">
            <DocumentCard v-for="document in this.$store.getters['documents/get']" :key="document.id_file" v-bind:document="document" />
        </div>
        <DocumentPreview />
    </div>
</template>

<script>
import RecapDocumentsNumber from "@/components/MaintenanceViewDocuments/RecapDocumentsNumber";
import DocumentCard from "@/components/MaintenanceViewDocuments/DocumentCard";
import DocumentPreview from "@/components/MaintenanceViewDocuments/DocumentPreview";

export default {
    name: "MaintenanceViewDocuments",
    components: {
        RecapDocumentsNumber,
        DocumentCard,
        DocumentPreview,
    },
};
</script>
