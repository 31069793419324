<template>
    <div class="pb-5">
        <span v-if="main_state === 2" class="maintenance-state to-do">
            {{ $t("home.to_do") }}
        </span>

        <span v-else-if="main_state === 3" class="maintenance-state done">
            {{ $t("home.done") }}
        </span>
        <v-tooltip top color="error" v-if="secondary_state !== 0">
            <template v-slot:activator="{ on, attrs }">
                <v-icon class="warning-icon" color="error" v-bind="attrs" v-on="on"> mdi-alert </v-icon>
            </template>
            <span>{{ $t("home.maintenance_late") }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: "MaintenanceState",
    props: {
        main_state: Number,
        secondary_state: Number,
    },
};
</script>
