<template>
    <v-card class="document-card" width="100%" max-width="500px">
        <v-card-text>
            <div class="d-flex flex-row justify-space-between">
                <div class="mr-2">
                    <p class="color-primary-darker mb-1 font-weight-bold">{{ document.filename }}</p>
                    <p v-show="document.description !== ''" class="color-gray-darker font-italic mb-0">{{ document.description }}</p>
                    <p class="font-italic mt-4 mb-0">{{ defineDate(document.date_creation) }}</p>
                </div>
                <div class="mt-auto mb-auto d-flex justify-end flex-wrap">
                    <v-btn icon @click="getDocumentPreview(document.path)">
                        <v-icon class="pa-1">mdi-eye</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <a :href="pdfLink(document.path)" target="_blank" download="download" class="download-link">
                            <v-icon class="pa-1">mdi-download</v-icon>
                        </a>
                    </v-btn>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from "moment";

export default {
    name: "DocumentCard",
    props: {
        document: Object,
    },
    methods: {
        defineDate(date) {
            moment.locale(this.language);
            let fromNow = moment(date).fromNow();
            return fromNow[0].toUpperCase() + fromNow.slice(1);
        },
        pdfLink(path) {
            return process.env.VUE_APP_FOURDATA_API_BASE_URL+ '/api/v1/maintenance/file' + path;
        },
        getDocumentPreview(path) {
            this.$store.dispatch("document_preview/show", { src: encodeURI(process.env.VUE_APP_FOURDATA_API_BASE_URL+ '/api/v1/maintenance/file' + path) });
        },
    },
};
</script>
