<template>
    <v-card-text>
        <v-row class="pl-3 pr-3 mb-n4" justify="space-between">
            <p class="mb-0 mr-4">{{ label }}</p>
            <v-radio-group v-model="checked" row class="ma-0 mt-n1">
                <v-radio :label="yes_label" value="true"></v-radio>
                <v-radio :label="no_label" value="false"></v-radio>
            </v-radio-group>
        </v-row>
    </v-card-text>
</template>

<script>
export default {
    name: "AppRadioYesNo",
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        yes_label: {
            type: String,
            required: false,
            default: "Oui",
        },
        no_label: {
            type: String,
            required: false,
            default: "Non",
        },
    },
    data() {
        return {
            checked: "false",
        };
    },
    mounted() {
        this.checked = this.value;
    },
    watch: {
        checked(newValue) {
            this.$emit("input", newValue);
        },
    },
};
</script>
