<template>
    <ActionButton :title="$t('maintenance_detail.useful_docs')" icon="mdi-file-document" :action="goDocuments" />
</template>

<script>
import ActionButton from "@/components/MaintenanceView/GoPagesRow/ActionButton.vue";

export default {
    name: "GoDocumentsButton",
    components: {
        ActionButton,
    },
    methods: {
        goDocuments() {
            return this.$router.push({ path: `${this.$route.path}/documents` });
        },
    },
};
</script>
