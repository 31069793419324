<template>
  <v-card class="mt-5">
    <AppVCardTitleWithButton label="Trou d’homme, détecteur de fuite, jaugeages électronique et manuel"
                             image_url="/img/implementedForms/62b97ed722d02/controlManhole.png"/>
    <AppLastBatteryValue :battery="battery" :timestamp="timestamp"/>
    <AppCheckbox v-model="value.changeBattery" label="Changement de la batterie du capteur"/>
    <v-card-text v-if="value.changeBattery">
      <v-checkbox v-model="value.activateSensorAfterChange" class="mb-n4 mt-n4"
                  label="Activer le capteur après changement de piles *" :rules="[v => !(value.changeBattery && !v)]"/>
    </v-card-text>
    <v-card-text>
      <v-row class="pb-4 pl-4 pr-4" justify="space-between" align="center">
        <div class="font-16">
          Nombre de relevés par jour: <span class="font-weight-bold">{{ value.device.nb_releve_par_jour }}</span>
        </div>
      </v-row>
    </v-card-text>
    <AppCheckbox v-if="value.device.nb_releve_par_jour === 1" v-model="value.changeDevice"
                 label="Remplacer le capteur par un capteur 6 RJ *" :required="true"/>
    <AppGaugingCheck v-model="value.lastMeasureCheck" :expected-measure="expectedMeasure" :timestamp="timestamp"/>
    <AppRadioYesNo v-model="value.offsetCorrection.checked" label="Ai-je déjà corrigé l'offset au préalable?"
                   yes_label="Oui" no_label="Non"/>
    <v-card-text class="pt-0 pb-0">
      <v-text-field v-if="value.offsetCorrection.checked === 'true'" label="Renseigner la correction *" type="number"
                    v-model="value.offsetCorrection.gauging"
                    :rules="[v => !(value.offsetCorrection.checked === 'true' && v === '')]" persistent-hint filled/>
    </v-card-text>
    <AppRadioYesNo v-model="value.localDisplayState.checked" label="Afficheur local" yes_label="OK"
                   no_label="HS - Mal réglé"/>
    <v-card-text class="pt-0 pb-0">
      <v-text-field v-if="value.localDisplayState.checked === 'true'" label="Renseigner la hauteur du liquide en cm"
                    type="number" v-model="value.localDisplayState.height" persistent-hint filled/>
      <v-card-text class="pt-0 pb-0 error--text" v-if="Math.abs(value.localDisplayState.height - expectedMeasure) > 2">
        <v-alert shaped text type="warning">Écart supérieur à 2cm, il faut modifier l'offset</v-alert>
      </v-card-text>
      <v-textarea v-model="value.localDisplayState.comment" placeholder="Commentaire" auto-grow filled></v-textarea>
      <v-row class="pb-4 pl-4 pr-4" justify="space-between" align="center">
        <div v-if="value.localDisplayState.checked === 'true'" class="font-16">
          Prendre en photo*
        </div>
        <div v-else class="font-16">
          Débrancher l’alimentation de l’afficheur et prendre une photo de l’afficheur débranché*
        </div>
      </v-row>
      <!-- <v-file-input prepend-icon="" prepend-inner-icon="mdi-paperclip" v-model="value.localDisplayState.file" placeholder="Ajouter une image" accept=".png, .jpg, .jpeg" filled /> -->
      <AppImageInput v-model="image" placeholder="Ajouter une image" :rules="[v => !!v]"/>
    </v-card-text>
    <AppRadioYesNoBoolean v-model="value.controlOfTheLeakDetector" no_label="Non Applicable"
                          label="Contrôle du fonctionnement du détecteur de fuite et complément du réservoir de glycol (antigel)."></AppRadioYesNoBoolean>

    <v-card-text v-if="value.controlOfTheLeakDetector">
      <AppRadioYesNo v-model="value.leakDetectorCompliant" label="Niveau: " yes_label="Conforme"
                     no_label="Non conforme"/>
    </v-card-text>
    <v-card-text v-if="value.controlOfTheLeakDetector && value.leakDetectorCompliant == 'false'" class="mt-n8 pb-0">
      <AppRadioYesNo v-model="value.needsComplementAntifreeze" label="Besoin complément du produit Antigel"/>
    </v-card-text>
    <v-card-text
        v-if="value.controlOfTheLeakDetector && value.leakDetectorCompliant == 'false' && value.needsComplementAntifreeze == 'true'"
        class="ml-4">
      <v-row class="pl-3 pr-3 mb-n4 mr-4" justify="space-between">
        <p class="mb-0">Quantité réassort estimée</p>
        <v-radio-group v-model="value.antifreezeVolume" row class="ma-0 mt-n1">
          <v-radio label="1L" value="1"></v-radio>
          <v-radio label="2L" value="2"></v-radio>
          <v-radio label="5L" value="5"></v-radio>
        </v-radio-group>
      </v-row>
    </v-card-text>
    <AppCheckboxWithConformityForm v-model="value.controlOfWeldsAndJoints"
                                   label="Trou d’homme, contrôle des soudures et joints"/>
  </v-card>
</template>

<script>
import moment from "moment";
import AppCheckbox from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/Checkbox";
import AppCheckboxWithConformityForm
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/CheckboxWithConformityForm";
import AppRadioYesNo from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNo";
import AppVCardTitleWithButton
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VCardTitleWithButton";
import AppLastBatteryValue
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/LastBatteryValue";
import AppGaugingCheck from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/GaugingCheck";
import AssetRepository from "@/repositories/AssetRepository";
import AppImageInput from "@/components/App/ImageInput";
import AppRadioYesNoBoolean
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNoBoolean.vue";

export default {
  name: "Manhole",
  components: {
    AppRadioYesNoBoolean,
    AppVCardTitleWithButton,
    AppCheckbox,
    AppCheckboxWithConformityForm,
    AppRadioYesNo,
    AppLastBatteryValue,
    AppGaugingCheck,
    AppImageInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      battery: -1,
      expectedMeasure: 0,
      timestamp: 0,
      image: null,
    };
  },
  async mounted() {
    AssetRepository.getLastAssetDtd().then(response => {
      this.battery = response.data.data.batterie;
      this.expectedMeasure = Math.floor(response.data.data.hauteur_liquide / 10);
      this.timestamp = moment(response.data.data.date_releve).unix();
      //this.lastMeasureCheck.expected = this.expectedMeasure;
      this.$emit("input", {...this.value, lastBattery: this.battery, lastEmisionDate: this.timestamp});
    });
  },
  watch: {
    image(newValue) {
      this.value.localDisplayState.file = newValue;
    },
  },
};
</script>
