<template>
    <v-card>
        <v-card-text>
            <v-row class="pa-4" justify="space-around">
                <p class="font-16 font-weight-bold mb-0 w_90p">{{ label }}</p>
                <v-icon color="primary" @click="$refs.signature.clear()">fa fa-trash</v-icon>
            </v-row>
        </v-card-text>
        <VueSignature id="signature" ref="signature" h="302px"></VueSignature>
    </v-card>
</template>

<script>
import VueSignature from "vue-signature";

export default {
    name: "AppSignature",
    components: {
        VueSignature,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: "Signature",
        },
        saveEvent: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    watch: {
        saveEvent() {
            this.$emit('input', this.$refs.signature.save());
        },
    },
};
</script>

<style scoped>
.w_90p {
    width: 90%;
}
</style>