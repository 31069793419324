import Repository from "./Repository";
import store from "../store";
const resource = "maintenance/access";

export default {
    /**
     * Checks with the api what the user is allowed to see.
     * @return {string}
     */
    check() {
        return Repository.get(`${process.env.VUE_APP_FOURDATA_API_BASE_URL}/api/v1/${resource}/check/${store.getters["authentication/user_uid"]}`, {
            headers: {
                Authorization: `Basic ${store.getters["authentication/user_uid"]}`,
            },
        })
            .then(function (response) {
                return response.data.data.access;
            })
            .catch(function () {
                store.dispatch("ajax/is_not_available");
                return "HAS_ALL_LEVEL_ACCESS";
            });
    },
};
