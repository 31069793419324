<template>
    <div v-if="files !== null" class="d-flex flex-row flex-wrap pb-4">
        <template v-if="files.images !== undefined">
            <div v-for="(image, index) in files.images" :key="index" class="image-container">
                <v-img class="image-preview pointer" @click="openDialogImage(image)" contain :src="displayImageInComment(image)" />
            </div>
        </template>
        <p class="width-100 pl-4 pt-4 text-left mb-0" v-if="files.file !== undefined"><a :href="file_url" target="_blank">Télécharger le rapport</a></p>
        <p class="width-100 pl-4 pt-4 text-left mb-0" v-if="files.archive !== undefined"><a :href="archive_url" target="_blank">Télécharger l'archive</a></p>
    </div>
</template>

<script>
export default {
    name: "ImagesContainer",
    props: {
        files: {
            type: Object,
        },
    },
    computed: {
        file_url() {
            return process.env.VUE_APP_BASE_URL + this.files.file;
        },
        archive_url() {
            return process.env.VUE_APP_BASE_URL + this.files.archive;
        },
    },
    methods: {
        displayImageInComment(image) {
            return process.env.VUE_APP_BASE_URL + image;
        },
        openDialogImage(image) {
            this.$store.dispatch("image_preview/show", { src: encodeURI(process.env.VUE_APP_BASE_URL + image) });
        },
    },
};
</script>
