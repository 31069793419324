<template>
    <Oleo100Preventive v-if="this.$store.getters['maintenance/get'].form.uid === '62b97ef5737a7'" />
    <Oleo100Curative v-else-if="this.$store.getters['maintenance/get'].form.uid === '62b97ed722d02'" />
</template>

<script>
import Oleo100Preventive from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/Oleo100Preventive";
import Oleo100Curative from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Curative";

export default {
    name: "InterventionReport",
    components: {
        Oleo100Preventive,
        Oleo100Curative,
    },
};
</script>