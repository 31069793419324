<template>
    <v-form id="oleo100-preventive-form">
        <v-card class="mt-5 pa-4">
            <v-text-field v-model="title" placeholder="Titre de l'opération" filled />
            <v-textarea v-model="spareParts" placeholder="Pièces détachées" auto-grow filled />
            <v-textarea v-model="comment" placeholder="Commentaire" auto-grow filled />
            <v-file-input v-model="uploaded_images" prepend-icon="" prepend-inner-icon="mdi-paperclip" placeholder="Ajouter des images" accept=".png, .jpg, .jpeg" multiple filled @change="compress" />
            <v-card class="mt-5">
                <AppSignature v-model="provider_signature" label="Signature du prestataire" :saveEvent="save_event" />
            </v-card>
            <v-btn class="mt-3 maintenance-detail-actions" height="40px" color="primary" @click="sendRepport">
                {{ $t("maintenance_detail.generate_intervention_report") }}
            </v-btn>
        </v-card>
    </v-form>
</template>

<script>
import AppSignature from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/Signature";
import MaintenanceRepository from "@/repositories/MaintenanceRepository";
import imageCompression from "browser-image-compression";
import { routes } from "@/models/Pages";

export default {
    name: "Oleo100Preventive",
    components: {
        AppSignature,
    },
    data() {
        return {
            title: "",
            spareParts: "",
            comment: "",
            images: [],
            uploaded_images: [],
            save_event: false,
            provider_signature: "",
        };
    },
    methods: {
        async sendRepport() {
            this.save_event = true;
            let data = new FormData();
            data.append("json_form", JSON.stringify(this.formatData()));
            data.append("global_data", JSON.stringify(await this.globalData()));

            this.$store.dispatch("ajax/in_recovery", { action: "Envoie du rapport" }, { root: true });
            MaintenanceRepository.validate(data).then((response) => {
                this.$store.dispatch("ajax/is_available", {}, { root: true });
                if (response.status === 200) {
                    this.report = null;

                    this.$store.dispatch("maintenance/load");
                    if (this.$store.getters["authentication/access_maintenance_list"]) {
                        this.$store.dispatch("maintenance_list/load");
                    }

                    this.$store.dispatch("snackbar/show", {
                        snackbar_text: "Rapport envoyé avec succès",
                        snackbar_type: "success",
                    });
                    this.dialog = false;

                    const go_back_data = routes[this.$route.name].appbar.go_back;
                    return this.$router.push({ name: go_back_data.page, params: go_back_data.params() });
                } else {
                    this.$store.dispatch("snackbar/show", {
                        snackbar_text: response.data.data.error,
                        snackbar_type: "error",
                    });
                }
            });
        },
        formatData() {
            let imagesTable = {
                title: "Images",
                content: [],
            };

            for (let index = 0; index < this.images.length; index++) {
                imagesTable.content.push({
                    type: "image",
                    file: this.images[index],
                });
            }

            return [
                {
                    title: "Pièces détachées",
                    content: [
                        {
                            type: "paragraph",
                            text: this.spareParts,
                        },
                    ],
                },
                {
                    title: "Commentaire",
                    content: [
                        {
                            type: "paragraph",
                            text: this.comment,
                        },
                    ],
                },
                imagesTable,
            ];
        },
        async globalData() {
            await new Promise((resolve) => setTimeout(resolve, 500));
            return {
                providerSignature: this.provider_signature,
                title: this.title,
            };
        },
        async compress(uploaded_images) {
            const toBase64 = (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });

            for (const index in uploaded_images) {
                let imageFile = uploaded_images[index];

                const options = {
                    maxSizeMB: 0.03,
                    maxWidthOrHeight: 1080,
                    useWebWorker: true,
                };

                try {
                    imageCompression(imageFile, options).then(function (compressedFile) {
                        let file = new File([compressedFile], compressedFile.name, {
                            type: compressedFile.type,
                            lastModified: new Date().getTime(),
                        });

                        let container = new DataTransfer();

                        container.items.add(file);

                        uploaded_images[index] = container.files[0];
                    });

                    this.images[index] = await toBase64(uploaded_images[index]);

                    this.$store.dispatch("snackbar/show", {
                        snackbar_text: this.$tc('maintenance_comment.image_added', this.images.length),
                        snackbar_type: "success",
                    });
                } catch (error) {
                    this.$store.dispatch("snackbar/show", {
                        snackbar_text: "Erreur lors de l'ajout de l'image",
                        snackbar_type: "error",
                    });
                }
            }
        },
    },
};
</script>

<style scoped>
#oleo100-preventive-form {
    width: 100%;
    max-width: 500px;
}
</style>
