import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { routes } from "../models/Pages";
import store from "../store/index";

Vue.use(VueRouter);

// Configuration for the router (routes is a listing of routes with additional information)
const routes_config: Array<RouteConfig> = [];
for (const key in routes) {
    routes_config.push(routes[key].route);
}

const router = new VueRouter({
    mode: "history",
    routes: routes_config,
});

router.beforeEach(async (to, from, next) => {
    // Changes the title of the tab
    if (to.name != undefined) {
        document.title = routes[to.name].tab_title;
    }

    // Checks connection and permissions. See in the store.
    await store.dispatch("authentication/connect", { user_uid: to.params.user_uid ?? "", maintenance_uid: to.params.maintenance_uid ?? "", route_name: to.name });
    store.dispatch("authentication/checkAuthorizations", { route_name: to.name });

    next();
});

export default router;
