<template>
    <v-card-text>
        <v-checkbox v-model="checked" class="mb-n6 mt-n6" :label="label"  :rules="[v => (v || !required)]"/>
    </v-card-text>
</template>

<script>
export default {
    name: "AppCheckbox",
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            checked: false,
        };
    },
    mounted() {
        this.checked = this.value;
    },
    watch: {
        checked(newValue) {
            this.$emit("input", newValue);
        },
    },
}
</script>