<template>
    <v-card-text>
        <v-row class="pb-4 pl-4 pr-4" justify="space-between" align="center">
            <div class="font-16">
                Dernier niveau de batterie * : <span v-if="battery !== -1" :class="['font-weight-bold', ...batteryColor]">{{ battery }}%</span><span v-else class="font-16 error--text"> Données indisponibles</span>
            </div>
            <v-chip v-if="battery !== -1" outlined>{{ il_y_a }}</v-chip>
        </v-row>
        <p class="text-right">* Donnée provenant de FUEL IT.<br />Si le niveau est inférieur à 70% changer la batterie du capteur</p>
    </v-card-text>
</template>

<script>
import moment from "moment";

export default {
    name: "AppLastBatteryValue",
    props: {
        battery: {
            type: Number,
            required: true,
        },
        timestamp: {
            type: Number,
            required: true,
        },
    },
    computed: {
        il_y_a() {
            moment.locale("fr");
            return moment.unix(this.timestamp).fromNow();
        },
        batteryColor() {
            if (this.battery > 70) return "success--text";
            if (this.battery > 30) return "warning--text";
            return "error--text";
        },
    },
};
</script>
