import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        themes: {
            light: {
                primary: '#c4cb0f',
                secondary: '#00626F',
                accent: '#D3D3D3',
                error: '#c1201f',
                info: '#32b3ee',
                success: '#169316',
                warning: '#c96d11',
                black: '#000000',
                white: '#ffffff',
            },
            dark: {
                primary: '#c4cb0f',
                secondary: '#00626F',
                accent: '#D3D3D3',
                error: '#c1201f',
                info: '#32b3ee',
                success: '#169316',
                warning: '#c96d11',
                black: '#000000',
                white: '#ffffff',
            },
        },
    },
});
