<template>
    <div class="maintenance-detail-actions">
        <div class="card-actions-container">
            <GoCommentsButton />
            <GoDocumentsButton />
            <GoHistoryButton />
        </div>
    </div>
</template>

<script>
import GoCommentsButton from "@/components/MaintenanceView/GoPagesRow/GoCommentsButton";
import GoDocumentsButton from "@/components/MaintenanceView/GoPagesRow/GoDocumentsButton";
import GoHistoryButton from "@/components/MaintenanceView/GoPagesRow/GoHistoryButton";

export default {
    name: "GoPagesRow",
    components: {
        GoCommentsButton,
        GoDocumentsButton,
        GoHistoryButton,
    },
};
</script>
