const state = () => ({
    /** Information about the image to be displayed. */
    image: {
        /** Url of the image. */
        src: "",
        /** Boolean to display or not the modal. */
        show: false,
    },
});

const mutations = {
    SET_SRC(state, src): void {
        state.image.src = src;
    },
    SHOW(state): void {
        state.image.show = true;
    },
    HIDE(state): void {
        state.image.show = false;
    },
};

const actions = {
    show({ commit }, payload): void {
        commit("SET_SRC", payload.src);
        commit("SHOW");
    },
    hide({ commit }): void {
        commit("HIDE");
    },
};

const getters = {
    show: (state) => state.image.show,
    src: (state) => state.image.src,
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
};
