<template>
    <v-tooltip bottom v-if="this.$store.getters['maintenance/get'].state.main_state !== 2">
        <template v-slot:activator="{ on }">
            <div class="maintenance-detail-actions" v-on="on">
                <v-btn class="mt-3" width="100%" height="40px" color="primary" disabled>
                    {{ $t("maintenance_detail.intervention_report") }}
                </v-btn>
            </div>
        </template>
        <span>Rapport déjà envoyé</span>
    </v-tooltip>

    <v-btn class="mt-3 maintenance-detail-actions" height="40px" color="primary" v-else-if="there_is_a_form_implemented" @click="goRepportPage">
        {{ $t("maintenance_detail.intervention_report") }}
    </v-btn>

    <v-dialog v-model="dialog" width="500" v-else>
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-3 maintenance-detail-actions" height="40px" color="primary" v-bind="attrs" v-on="on">
                {{ $t("maintenance_detail.intervention_report") }}
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5 lighten-2"> Envoyer un rapport </v-card-title>

            <div id="dropzone" class="ma-5 d-flex flex-column align-center pt-10 pb-5" @click="openAddFileDialog">
                <div class="empty d-flex flex-column align-center" v-if="report === null">
                    <v-icon class="fa fa-cloud-upload"></v-icon>
                    <p class="font-16 font-weight-bold">Importer un fichier</p>
                    <p class="font-12">Le format accepté est le .pdf</p>
                </div>
                <div class="d-flex flex-row justify-center align-center" v-else>
                    <span class="width-80 text-center mr-2 mb-5">{{ report.name }}</span>
                    <v-icon class="fa fa-times" @click="deleteFile"></v-icon>
                </div>
                <v-file-input v-model="report" style="display: none" ref="repport_input" accept=".pdf" />
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog"> Annuler </v-btn>
                <v-btn color="primary" text @click="sendReport" :disabled="report === null"> Envoyer </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import MaintenanceRepository from "@/repositories/MaintenanceRepository";

export default {
    name: "GenerateReportButton",
    data() {
        return {
            dialog: false,
            report: null,
            last_action: "",
        };
    },
    computed: {
        there_is_a_form_implemented() {
            const implementedForms = ['62b97ef5737a7', '62b97ed722d02'];
            return implementedForms.includes(this.$store.getters['maintenance/get'].form.uid)
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.report = null;
        },
        sendReport() {
            if (this.report !== null) {
                let data = new FormData();
                data.append("file", this.report);

                this.$store.dispatch("ajax/in_recovery", { action: "Envoie du rapport" }, { root: true });
                MaintenanceRepository.validate(data).then((response) => {
                    this.$store.dispatch("ajax/is_available", {}, { root: true });
                    if (response.status === 200) {
                        this.report = null;

                        this.$store.dispatch("maintenance/load");
                        if (this.$store.getters["authentication/access_maintenance_list"]) {
                            this.$store.dispatch("maintenance_list/load");
                        }

                        this.$store.dispatch("snackbar/show", {
                            snackbar_text: "Rapport envoyé avec succès",
                            snackbar_type: "success",
                        });
                        this.dialog = false;
                    } else {
                        this.$store.dispatch("snackbar/show", {
                            snackbar_text: response.data.data.error,
                            snackbar_type: "error",
                        });
                    }
                });
            }
        },
        openAddFileDialog() {
            if (this.report === null && this.last_action !== "deleteFile") {
                this.$refs.repport_input.$refs.input.click();
            }

            this.last_action = "openAddFileDialog";
        },
        deleteFile() {
            this.report = null;
            this.last_action = "deleteFile";
        },
        goRepportPage() {
            this.$router.push({ path: `${this.$route.path}/report` })
        }
    },
};
</script>
