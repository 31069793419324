<template>
    <v-card class="maintenance-detail-card mt-5" width="100%" max-width="500px">
        <v-card-text>
            <div class="mb-4">
                <p class="font-16 font-weight-bold">
                    {{ $t("maintenance_detail.client_info") }}
                </p>
                <p class="ma-0 color-primary-darker">Entreprise</p>
                <p>{{ maintenance.client.entreprise }}</p>

                <p class="ma-0 color-primary-darker">
                    {{ $t("maintenance_detail.client") }}
                </p>
                <p>{{ maintenance.client.name }}</p>
                <div v-if="maintenance.purchase_order_number !== ''">
                    <p class="ma-0 color-primary-darker">{{ $t("maintenance_detail.purchase_order_number") }}</p>
                    <p>{{ maintenance.purchase_order_number }}</p>
                </div>

                <p class="ma-0 color-primary-darker">
                    {{ $t("maintenance_detail.operation_address") }}
                </p>
                <p>
                    {{ maintenance.address.numero }} <span v-html="maintenance.address.adresse"></span>
                    {{ maintenance.address.code_postal }}
                    {{ maintenance.address.ville }}
                </p>

                <p class="ma-0 color-primary-darker">
                    {{ $t("maintenance_detail.operation_contact") }}
                </p>
                <v-row no-gutters>
                    <v-col cols="12" sm="6" class="d-flex flex-row">
                        <v-icon> mdi-account </v-icon>
                        <p class="mt-auto mb-auto ml-2" v-html="maintenance.contact.name"></p>
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex flex-row">
                        <v-icon> mdi-phone </v-icon>
                        <p class="mt-auto mb-auto ml-2">
                            {{ maintenance.contact.phone }}
                        </p>
                    </v-col>
                </v-row>
                <div class="d-flex flex-row">
                    <v-icon> mdi-at </v-icon>
                    <p class="mt-auto mb-auto ml-2">{{ maintenance.contact.mail }}</p>
                </div>
            </div>

            <p class="font-16 font-weight-bold">
                {{ $t("maintenance_detail.operation_info") }}
            </p>
            <p class="ma-0 color-primary-darker">
                {{ $t("maintenance_detail.operation_name") }}
            </p>
            <p v-html="maintenance.title"></p>
            <p class="ma-0 color-primary-darker">
                {{ $t("maintenance_detail.equipment_type") }}
            </p>
            <p class="text-capitalize">{{ maintenance.type }}</p>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "MaintenanceDetail",
    computed: {
        maintenance() {
            return this.$store.getters["maintenance/get"];
        },
    },
};
</script>
