<template>
  <v-card-text>
    <v-row class="pl-3 mb-n4" justify="space-between">
      <div>
        <v-row align="center" no-gutters>
          <v-col class="d-flex" cols="auto">
            <p class="mb-0">{{ label }}</p>
          </v-col>
          <v-col class="d-flex" cols="auto">
            <v-dialog v-if="image_url" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>fa fa-circle-info</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-img :src="image_url"></v-img>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </div>
      <v-radio-group v-model="checked" row class="ma-0 mt-n1">
        <v-radio :label="yes_label" :value=true></v-radio>
        <v-radio :label="no_label" :value=false></v-radio>
      </v-radio-group>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "AppRadioYesNoBoolean",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    yes_label: {
      type: String,
      required: false,
      default: "Oui",
    },
    no_label: {
      type: String,
      required: false,
      default: "Non",
    },
    image_url: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  mounted() {
    this.checked = this.value;
  },
  watch: {
    checked(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
