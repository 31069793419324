import store from "../store";
import UnauthorizedPage from "../views/Unauthorized.vue";
import MaintenanceListPage from "../views/MaintenenceList.vue";
import MaintenanceViewPage from "../views/MaintenanceView.vue";
import MaintenanceViewCommentsPage from "../views/MaintenanceViewComments.vue";
import MaintenanceViewDocumentsPage from "../views/MaintenanceViewDocuments.vue";
import MaintenanceViewHistoryPage from "../views/MaintenanceViewHistory.vue";
import MaintenanceViewReport from "../views/MaintenanceViewReport.vue";

export const routes = {
    Unauthorized: {
        route: {
            path: "/Unauthorized",
            name: "Unauthorized",
            component: UnauthorizedPage,
        },
        appbar: null,
        tab_title: "Maintenance - Non autorisé",
    },
    MaintenanceList: {
        route: {
            path: "/:user_uid",
            name: "MaintenanceList",
            component: MaintenanceListPage,
        },
        appbar: {
            title: "home.title",
            go_back: null,
        },
        tab_title: "Liste des maintenances",
    },
    MaintenanceView: {
        route: {
            path: "/:user_uid/maintenance/:maintenance_uid",
            name: "MaintenanceView",
            component: MaintenanceViewPage,
        },
        appbar: {
            title: "maintenance_detail.title",
            go_back: {
                access: ["ALL"],
                page: "MaintenanceList",
                params: function () {
                    return { user_uid: store.getters["authentication/user_uid"] };
                },
            },
        },
        tab_title: "Maintenance",
    },
    MaintenanceViewComments: {
        route: {
            path: "/:user_uid/maintenance/:maintenance_uid/comments",
            name: "MaintenanceViewComments",
            component: MaintenanceViewCommentsPage,
        },
        appbar: {
            title: "maintenance_comment.title",
            go_back: {
                access: ["ALL", "MAINTENANCE"],
                page: "MaintenanceView",
                params: function () {
                    return { user_uid: store.getters["authentication/user_uid"], maintenance_uid: store.getters["authentication/maintenance_uid"] };
                },
            },
        },
        tab_title: "Maintenance - Commentaires",
    },
    MaintenanceViewDocuments: {
        route: {
            path: "/:user_uid/maintenance/:maintenance_uid/documents",
            name: "MaintenanceViewDocuments",
            component: MaintenanceViewDocumentsPage,
        },
        appbar: {
            title: "maintenance_documents.title",
            go_back: {
                access: ["ALL", "MAINTENANCE"],
                page: "MaintenanceView",
                params: function () {
                    return { user_uid: store.getters["authentication/user_uid"], maintenance_uid: store.getters["authentication/maintenance_uid"] };
                },
            },
        },
        tab_title: "Maintenance - Documents",
    },
    MaintenanceViewHistory: {
        route: {
            path: "/:user_uid/maintenance/:maintenance_uid/history",
            name: "MaintenanceViewHistory",
            component: MaintenanceViewHistoryPage,
        },
        appbar: {
            title: "maintenance_history.title",
            go_back: {
                access: ["ALL", "MAINTENANCE"],
                page: "MaintenanceView",
                params: function () {
                    return { user_uid: store.getters["authentication/user_uid"], maintenance_uid: store.getters["authentication/maintenance_uid"] };
                },
            },
        },
        tab_title: "Maintenance - Historique",
    },
    MaintenanceViewReport: {
        route: {
            path: "/:user_uid/maintenance/:maintenance_uid/report",
            name: "MaintenanceViewReport",
            component: MaintenanceViewReport,
        },
        appbar: {
                title: "maintenance_report.title",
            go_back: {
                access: ["ALL", "MAINTENANCE"],
                page: "MaintenanceView",
                params: function () {
                    return { user_uid: store.getters["authentication/user_uid"], maintenance_uid: store.getters["authentication/maintenance_uid"] };
                },
            },
        },
        tab_title: "Maintenance - Rapport",
    },
};
