<template>
    <p class="mt-5 mb-2 text-center color-gray-darker">
        {{ $t("home.maintenance_number", { number: itemsCount }) }}
    </p>
</template>

<script>
export default {
    name: "RecapMaintenanceNumber",
    props: {
        itemsCount: {
            type: Number,
            required: true,
        }
    }
};
</script>
