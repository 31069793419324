import GenericRepository from "./GenericRepository";
import store from "../store";

export default {
    /**
     * Return last data of an asset.
     * @return {string}
     */
    getLastAssetDtd() {
        return GenericRepository.get(process.env.VUE_APP_FOURDATA_API_BASE_URL + "/api/v1/asset/" + store.getters["maintenance/get"].cuve.uid + "/device_traited_data/latest")
    },
};
