<template>
    <div class="app-container">
        <MaintenanceDetail />
        <GoPagesRow />
        <GenerateReportButton />
    </div>
</template>

<script>
import MaintenanceDetail from "@/components/MaintenanceView/MaintenanceDetail";
import GoPagesRow from "@/components/MaintenanceView/GoPagesRow/GoPagesRow";
import GenerateReportButton from "@/components/MaintenanceView/GenerateReportButton";

export default {
    name: "MaintenanceView",
    components: {
        MaintenanceDetail,
        GoPagesRow,
        GenerateReportButton,
    },
};
</script>
