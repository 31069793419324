<template>
    <v-card-text>
        <p class="font-16 font-weight-bold mb-n2">{{ label }}</p>
    </v-card-text>
</template>

<script>
export default {
    name: "VCardTitle",
    props: {
        label: {
            type: String,
            required: true,
        },
    },
};
</script>