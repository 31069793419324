<template>
    <div class="maintenance-app">
        <HistoryTimeline v-bind:maintenanceId="this.$route.params.uid"></HistoryTimeline>
        <ImageViewDialog />
    </div>
</template>

<script>
import HistoryTimeline from "@/components/MaintenanceViewHistory/HistoryTimeline";
import ImageViewDialog from "@/components/MaintenanceViewComments/ImageViewDialog";

export default {
    name: "MaintenanceViewHistory",
    components: {
        HistoryTimeline,
        ImageViewDialog,
    },
};
</script>
