<template>
    <v-btn icon @click="goBack" class="btn-back" v-if="this.$store.getters['ajax/available'] === 'YES'">
        <v-icon color="secondary">mdi-arrow-left</v-icon>
    </v-btn>
</template>

<script>
import { routes } from "../../models/Pages";

export default {
    name: "GoBackButton",
    methods: {
        goBack() {
            let go_back_data = routes[this.$route.name].appbar.go_back;
            return this.$router.push({ name: go_back_data.page, params: go_back_data.params() });
        },
    },
};
</script>
