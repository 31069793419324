<template>
    <div class="app-container">
        <InterventionReport />
    </div>
</template>

<script>
import InterventionReport from "../components/MaintenanceViewReport/InterventionReport";

export default {
    name: "MaintenanceViewReport",
    components: {
        InterventionReport,
    },
};
</script>
