const state = () => ({
    /** Status of loads in the application. Values: [PROCESS_OF_RECOVERY, YES, NO] */
    available: "PROCESS_OF_RECOVERY",
    /** Current action (displayed below the spinner). */
    action: "Récupération en cours",
    /** Checks the number of asynchronous actions that take place at the same time. */
    parallel_process: 0,
});

const mutations = {
    IS_AVAILABLE(state) {
        state.available = "YES";
    },
    IS_NOT_AVAILABLE(state) {
        state.available = "NO";
    },
    IN_RECOVERY(state) {
        state.available = "PROCESS_OF_RECOVERY";
    },
    SET_ACTION(state, action) {
        state.action = action;
    },
};

const actions = {
    is_available({ commit, state }) {
        state.parallel_process--;
        if (state.available !== "NO" && state.parallel_process === 0) {
            commit("IS_AVAILABLE");
        }
    },
    in_recovery({ commit, state }, payload) {
        if(state.available !== "NO") {
            state.parallel_process++;
            commit("SET_ACTION", payload.action);
            commit("IN_RECOVERY");
        }
    },
    is_not_available({ commit, state }) {
        state.parallel_process--;
        commit("IS_NOT_AVAILABLE");
    },
};

const getters = {
    available: (state) => state.available,
    action: (state) => state.action,
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
};
