import Repository from "./Repository";
import store from "../store";
const resource = "/maintenance";

export default {
    /**
     * Retrieves the list of all visible maintenance operations.
     * @return {Promise<Object>}
     */
    getAll() {
        return Repository.get(`${process.env.VUE_APP_FOURDATA_API_BASE_URL}/api/v1${resource}/`,{
            headers: {
                Authorization: `Basic ${store.getters["authentication/user_uid"]}`,
            },
        });
    },

    /**
     * Returns the requested maintenance.
     * @returns {Promise<Object>}
     */
    get() {
        return Repository.get(`${process.env.VUE_APP_FOURDATA_API_BASE_URL}/api/v1${resource}/${atob(store.getters["authentication/maintenance_uid"])}`, {
            headers: {
                Authorization: `Basic ${store.getters["authentication/user_uid"]}`,
            },
        });
    },

    /**
     * Adds a comment to the desired maintenance.
     * @param {Object} data
     * @returns {Promise<Object>}
     */
    addComment(data) {
        return Repository.post(`${process.env.VUE_APP_FOURDATA_API_BASE_URL}/api/v1${resource}/comment/${atob(store.getters["authentication/maintenance_uid"])}`, data, {
            validateStatus: () => true,
            headers: {
                Authorization: `Basic ${store.getters["authentication/user_uid"]}`,
            },
        });
    },

    /**
     * Retrieves the files accessible by the provider.
     * @return {Promise<Object>}
     */
    getFile() {
        return Repository.get(`${process.env.VUE_APP_FOURDATA_API_BASE_URL}/api/v1${resource}/file`, {
            headers: {
                Authorization: `Basic ${store.getters["authentication/user_uid"]}`,
            },
        });
    },

    /**
     * Sends a request to validate the maintenance.
     * @param {Object} data
     * @return {Promise<Object>}
     */
    validate(data) {
        return Repository.post(`${process.env.VUE_APP_FOURDATA_API_BASE_URL}/api/v1${resource}/end_of_maintenance/${atob(store.getters["authentication/maintenance_uid"])}`, data, {
            validateStatus: () => true,
            headers: {
                Authorization: `Basic ${store.getters["authentication/user_uid"]}`,
            },
        });
    },
};
