<template>
    <ActionButton :title="$t('maintenance_detail.comments')" icon="mdi-comment-plus" :action="goComments" />
</template>

<script>
import ActionButton from "@/components/MaintenanceView/GoPagesRow/ActionButton.vue";

export default {
    name: "GoCommentsButton",
    components: {
        ActionButton,
    },
    methods: {
        goComments() {
            return this.$router.push({ path: `${this.$route.path}/comments` });
        },
    },
};
</script>
