<template>
  <v-card class="mt-5">
    <AppVCardTitle label="Sécurité"/>

    <!-- <AppCheckboxWithButton v-model="value.hasScamLadder" label="Echelle harnachée *" image_url="/img/implementedForms/62b97ed722d02/scamLadder.png" :required="true"/> -->
    <AppRadioYesNoBoolean v-model="value.hasScamLadder" label="Echelle harnachée *"
                        image_url="/img/implementedForms/62b97ed722d02/scamLadder.png"></AppRadioYesNoBoolean>
    <v-card-text class="pt-0 pb-0">
      <v-textarea v-if="!value.hasScamLadder" v-model="value.commentScamLadder" placeholder="Commentaire" auto-grow
                  filled></v-textarea>
    </v-card-text>
    <AppRadioYesNoBoolean v-model="value.hasHarnessWithLifeLine"
                        label="Baudrier avec ligne de vie *"></AppRadioYesNoBoolean>
    <v-card-text class="pt-0 pb-0">
      <v-textarea v-if="!value.hasHarnessWithLifeLine" v-model="value.commentHarnessWithLifeLine"
                  placeholder="Commentaire" auto-grow filled></v-textarea>
    </v-card-text>
    <v-card-text>
      <v-checkbox v-model="value.hasPersonalProtectiveEquipment" class="mt-n4"
                  label="EPI : Chaussures de sécurité, casque, lunettes, gants et vêtements à bandes réfléchissantes *"
                  :rules="[v => v]"/>
    </v-card-text>
  </v-card>
</template>

<script>
import AppCheckboxWithButton
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/CheckboxWithButton";
import AppVCardTitle from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VCardTitle";
import AppRadioYesNo from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNo.vue";
import AppRadioYesNoBoolean
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNoBoolean.vue";

export default {
  name: "PreventivePlan",
  components: {
    AppRadioYesNoBoolean,
    AppRadioYesNo,
    AppCheckboxWithButton,
    AppVCardTitle,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>