<template>
    <v-dialog v-model="display_image_dialog" max-width="500px">
        <v-img contain :src="image_src" height="100%" />
    </v-dialog>
</template>

<script>
export default {
    name: "ImageViewDialog",
    computed: {
        image_src() {
            return this.$store.getters["image_preview/src"];
        },
        display_image_dialog: {
            get() {
                return this.$store.getters["image_preview/show"];
            },
            set(value) {
                this.$store.dispatch(`image_preview/${value ? "show" : "hide"}`);
            },
        },
    },
};
</script>
