<template>
    <div class="app-container">
        <div class="my-4" style="max-width: 400px; width: 100%;">
            <v-text-field v-model="search" :placeholder="$t('global.search')" prepend-inner-icon="mdi-magnify" solo hide-details></v-text-field>
        </div>
        <RecapMaintenanceNumber :itemsCount="maintenances.length" />
        <div class="app-container">
            <MaintenanceCard v-for="maintenance in maintenances" :key="maintenance.id_maintenance" v-bind:maintenance="maintenance" />
        </div>
    </div>
</template>

<script>
import RecapMaintenanceNumber from "@/components/MaintenanceList/RecapMaintenanceNumber";
import MaintenanceCard from "@/components/MaintenanceList/MaintenanceCard/MaintenanceCard.vue";

export default {
    name: "MaintenenceList",
    components: {
        RecapMaintenanceNumber,
        MaintenanceCard,
    },
    data() {
        return {
            search: '',
        }
    },
    computed: {
        maintenances() {
            let maintenances = this.$store.getters['maintenance_list/maintenance_list']
            return maintenances.filter(maintenance => {
                if(this.search === '') return true

                const lowercase = this.search.toLowerCase()
                return (maintenance.title !== null && maintenance.title.toLowerCase().includes(lowercase))
                   || (maintenance.date_maintenance !== null && maintenance.date_maintenance.toLowerCase().includes(lowercase))
                   || (maintenance.client.entreprise !== null && maintenance.client.entreprise.toLowerCase().includes(lowercase))
                   || (maintenance.client.name !== null && maintenance.client.name.toLowerCase().includes(lowercase))
                   || (maintenance.address.code_postal && maintenance.address.code_postal.toLowerCase().includes(lowercase))
                   || (maintenance.address.ville !== null && maintenance.address.ville.toLowerCase().includes(lowercase))
            });
        }
    }
};
</script>
