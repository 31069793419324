<template>
    <v-dialog v-model="show" max-width="650px">
        <v-card>
            <v-card-text class="pb-0 pt-4">
                <div>
                    <pdf ref="pdf" :src="src" :page="page" :rotate="rotate" @num-pages="numPages = $event"></pdf>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn icon @click="rotate -= 90">
                    <v-icon>mdi-undo</v-icon>
                </v-btn>
                <v-btn icon @click="rotate += 90">
                    <v-icon>mdi-redo</v-icon>
                </v-btn>
                <v-spacer />
                {{ page }} /{{ numPages }}
                <v-spacer />
                <v-btn icon @click="switchPageMinus()" :disabled="page === 1">
                    <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
                </v-btn>
                <v-btn icon @click="switchPagePlus()" :disabled="page === numPages">
                    <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import pdf from "vue-pdf";

export default {
    name: "DocumentPreview",
    components: {
        pdf,
    },
    data() {
        return {
            page: 1,
            numPages: 0,
            rotate: 0,
        };
    },
    computed: {
        show: {
            get() {
                return this.$store.getters["document_preview/show"];
            },
            set(value) {
                this.$store.dispatch(`document_preview/${value ? "show" : "hide"}`);
            },
        },
        src() {
            return this.$store.getters["document_preview/src"];
        },
    },
    methods: {
        switchPagePlus() {
            if (this.page + 1 <= this.numPages) {
                this.page += 1;
            }
        },
        switchPageMinus() {
            if (this.page - 1 > 0) {
                this.page -= 1;
            }
        },
    },
};
</script>
