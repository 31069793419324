import AccessRepository from "@/repositories/AccessRepository";

const state = () => ({
    /** Uid of the user in base64. */
    user_uid: "",
    /** Uid of maintenance in base64. */
    maintenance_uid: "",
    /** User access level. Values: [UNDEFINED, NONE, MAINTENANCE, ALL ] */
    access_level: "UNDEFINED",
    /** Global access to the application or not. */
    access: false,
});

const mutations = {
    SET_USER_UID(state, user_uid) {
        state.user_uid = user_uid;
    },
    SET_MAINTENANCE_UID(state, maintenance_uid) {
        state.maintenance_uid = maintenance_uid;
    },
    HAS_ALL_LEVEL_ACCESS(state) {
        state.access_level = "ALL";
    },
    HAS_MAINTENANCE_LEVEL_ACCESS(state) {
        state.access_level = "MAINTENANCE";
    },
    HAS_NOT_LEVEL_ACCESS(state) {
        state.access_level = "NONE";
    },
    HAS_ACCESS(state) {
        state.access = true;
    },
    HAS_NOT_ACCESS(state) {
        state.access = false;
    },
};

const actions = {
    async connect({ commit, state, dispatch, rootGetters }, payload) {
        if (payload.maintenance_uid !== state.maintenance_uid) {
            commit("SET_MAINTENANCE_UID", payload.maintenance_uid);
        }

        if (payload.user_uid !== state.user_uid) {
            commit("SET_USER_UID", payload.user_uid);

            const access = await AccessRepository.check();
            dispatch("ajax/in_recovery", { action: "Vérification des droits d'accès ..." }, { root: true });
            if (access === "HAS_ALL_LEVEL_ACCESS") {
                commit("HAS_ALL_LEVEL_ACCESS");

                dispatch("maintenance_list/load", {}, { root: true });
                dispatch("documents/load", {}, { root: true });

                if (payload.route_name !== "MaintenanceList") {
                    dispatch("maintenance/load", payload.maintenance_uid, { root: true });
                }
            } else if (access === "HAS_MAINTENANCE_LEVEL_ACCESS") {
                commit("HAS_MAINTENANCE_LEVEL_ACCESS");
                dispatch("maintenance/load", payload.maintenance_uid, { root: true });
                dispatch("documents/load", {}, { root: true });
            } else {
                commit("HAS_NOT_LEVEL_ACCESS");
            }

            dispatch("ajax/is_available", {}, { root: true });
        }

        if (payload.user_uid === "") {
            commit("HAS_NOT_LEVEL_ACCESS");
        }
    },
    checkAuthorizations({ commit, state }, payload) {
        switch (payload.route_name) {
            case "Unauthorized":
                commit("HAS_ACCESS");
                break;
            case "MaintenanceList":
                if (state.access_level === "ALL") {
                    commit("HAS_ACCESS");
                } else {
                    commit("HAS_NOT_ACCESS");
                }
                break;
            case "MaintenanceView":
                if (state.access_level === "ALL" || state.access_level === "MAINTENANCE") {
                    commit("HAS_ACCESS");
                } else {
                    commit("HAS_NOT_ACCESS");
                }
                break;
            case "MaintenanceViewComments":
                if (state.access_level === "ALL" || state.access_level === "MAINTENANCE") {
                    commit("HAS_ACCESS");
                } else {
                    commit("HAS_NOT_ACCESS");
                }
                break;
            case "MaintenanceViewDocuments":
                if (state.access_level === "ALL" || state.access_level === "MAINTENANCE") {
                    commit("HAS_ACCESS");
                } else {
                    commit("HAS_NOT_ACCESS");
                }
                break;
            case "MaintenanceViewHistory":
                if (state.access_level === "ALL" || state.access_level === "MAINTENANCE") {
                    commit("HAS_ACCESS");
                } else {
                    commit("HAS_NOT_ACCESS");
                }
                break;
            case "MaintenanceViewReport":
                if (state.access_level === "ALL" || state.access_level === "MAINTENANCE") {
                    commit("HAS_ACCESS");
                } else {
                    commit("HAS_NOT_ACCESS");
                }
                break;
            default:
                commit("HAS_NOT_ACCESS");
                break;
        }
    },
};

const getters = {
    access: (state) => {
        return state.access;
    },
    access_maintenance_list: (state) => {
        return state.access_level === "ALL";
    },
    access_level: (state) => {
        return state.access_level;
    },
    user_uid: (state) => {
        return state.user_uid;
    },
    maintenance_uid: (state) => {
        return state.maintenance_uid;
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
};
