<template>
    <ActionButton :title="$t('maintenance_detail.history')" icon="mdi-format-list-bulleted" :action="goHistory" />
</template>

<script>
import ActionButton from "@/components/MaintenanceView/GoPagesRow/ActionButton.vue";

export default {
    name: "GoHistoryButton",
    components: {
        ActionButton,
    },
    methods: {
        goHistory() {
            return this.$router.push({ path: `${this.$route.path}/history` });
        },
    },
};
</script>
