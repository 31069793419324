<template>
    <v-card width="30%" height="100px" class="card-action mt-3 mr-1 ml-1" @click="action">
        <v-card-text>
            <div class="ma-auto">
                <v-icon class="ma-2">{{ icon }}</v-icon>
                <p class="font-12 line-height-15">{{ title }}</p>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "ActionButton",
    props: {
        title: String,
        icon: String,
        action: Function,
    },
};
</script>
