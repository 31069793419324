import MaintenanceRepository from "@/repositories/MaintenanceRepository";

const state = () => ({
    /** List of documents to be displayed. */
    documents: {},
});

const mutations = {
    SET_DOCUMENTS(state, documents) {
        state.documents = documents;
    },
};

const actions = {
    load({ commit, dispatch }) {
        dispatch("ajax/in_recovery", { action: "Récupération des documents ..." }, { root: true });
        MaintenanceRepository.getFile()
            .then((res) => {
                commit("SET_DOCUMENTS", res.data.data);
                dispatch("ajax/is_available", {}, { root: true });
            })
            .catch(() => {
                dispatch("ajax/is_not_available", {}, { root: true });
            });
    },
    setDocuments({ commit }, payload) {
        commit("SET_DOCUMENTS", payload.documents);
    },
};

const getters = {
    get: (state) => state.documents,
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
};
