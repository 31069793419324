<template>
    <v-card class="maintenance-card" width="100%" max-width="500px">
        <v-card-text>
            <MaintenanceState :main_state="maintenance.state.main_state" :secondary_state="maintenance.state.secondary_state" />

            <p class="maintenance-title ma-0" v-html="maintenance.title"></p>
            <div>
                <span class="color-primary-darker">{{ $t("home.maintenance_date") }}</span>
                <strong> {{ maintenance.date_maintenance }}</strong>
            </div>
            <div>
                <span class="color-primary-darker">Entreprise</span>
                {{ maintenance.client.entreprise }}
            </div>
            <div>
                <span class="color-primary-darker">{{ $t("home.client") }}</span>
                {{ maintenance.client.name }}
            </div>
            <div>
                <span class="color-primary-darker">Adresse</span>
                {{ maintenance.address.numero }} {{ maintenance.address.adresse }}, {{ maintenance.address.code_postal }} {{ maintenance.address.ville }}
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" width="100%" class="maintenance-button" @click="viewMaintenance(maintenance)">
                {{ $t("home.see_maintenance") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import MaintenanceState from "@/components/MaintenanceList/MaintenanceCard/MaintenanceState";

export default {
    name: "MaintenanceCard",
    components: {
        MaintenanceState,
    },
    props: {
        maintenance: Object,
    },
    methods: {
        viewMaintenance(maintenance) {
            this.$store.dispatch("maintenance/setMaintenance", { maintenance: maintenance }, { root: true });
            this.$router.push({
                path: `/${this.$store.getters["authentication/user_uid"]}/maintenance/${btoa(maintenance.uid)}`,
            });
        },
    },
};
</script>
