<template>
    <v-snackbar v-model="snackbar" :color="snackbar_type">
        {{ snackbar_text }}
        <template v-slot:action>
            <v-icon @click="snackbar = false" color="white">mdi-close</v-icon>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "Snackbar",
    computed: {
        snackbar: {
            get() {
                return this.$store.getters["snackbar/snackbar"];
            },
            set(value) {
                this.$store.dispatch("snackbar/hide");
            },
        },
        snackbar_type() {
            return this.$store.getters["snackbar/snackbar_type"];
        },
        snackbar_text() {
            return this.$store.getters["snackbar/snackbar_text"];
        },
    },
};
</script>
