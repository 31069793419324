<template>
    <div id="spinner-component">
        <div id="spinner-container">
            <svg viewBox="0 0 100 100">
                <defs>
                    <filter id="shadow">
                        <feDropShadow dx="0" dy="0" stdDeviation="1.5" flood-color="#fc6767" />
                    </filter>
                </defs>
                <circle id="spinner" cx="50" cy="50" r="45" />
            </svg>
        </div>
        <p>{{ this.$store.getters["ajax/available"] === "PROCESS_OF_RECOVERY" ? this.$store.getters["ajax/action"] : "Chargement ..." }}</p>
    </div>
</template>

<script>
export default {
    name: "Spinner",
};
</script>

<style scoped>
#spinner-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#spinner-container {
    width: 70px;
    height: 70px;
    margin-bottom: 1em;
}

#spinner {
    transform-origin: center;
    animation-name: animation;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier;
    animation-iteration-count: infinite;
    fill: transparent;
    stroke: #d1d812;
    stroke-width: 7px;
    stroke-linecap: round;
}

@keyframes animation {
    0% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -105;
    }
    50% {
        stroke-dasharray: 80 10;
        stroke-dashoffset: -160;
    }
    100% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -300;
    }
}
</style>
