const state = () => ({
    /** Boolean to display or not the snackbar. */
    snackbar: false,
    /** Text to be displayed. */
    snackbar_text: "",
    /** Type of snack bar. */
    snackbar_type: null,
});

const mutations = {
    SHOW(state) {
        state.snackbar = true;
    },
    HIDE(state) {
        state.snackbar = false;
    },
    SET_SNACKBAR_TEXT(state, snackbar_text) {
        state.snackbar_text = snackbar_text;
    },
    SET_SNACKBAR_TYPE(state, snackbar_type) {
        state.snackbar_type = snackbar_type;
    },
};

const actions = {
    show({ commit }, payload) {
        commit("SET_SNACKBAR_TEXT", payload.snackbar_text);
        commit("SET_SNACKBAR_TYPE", payload.snackbar_type);
        commit("SHOW");
    },
    hide({ commit }) {
        commit("HIDE");
    },
};

const getters = {
    snackbar: (state) => state.snackbar,
    snackbar_text: (state) => state.snackbar_text,
    snackbar_type: (state) => state.snackbar_type,
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
};
