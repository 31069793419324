import MaintenanceRepository from "@/repositories/MaintenanceRepository";

const state = () => ({
    /** List of maintenances that the user can see. */
    maintenance_list: [],
});

const mutations = {
    SET_MAINTENANCE_LIST(state, maintenance_list) {
        state.maintenance_list = maintenance_list;
    },
};

const actions = {
    load({ commit, dispatch }) {
        dispatch("ajax/in_recovery", { action: "Récupération de la liste des maintenances ..." }, { root: true });
        MaintenanceRepository.getAll()
            .then((res) => {
                commit("SET_MAINTENANCE_LIST", res.data.data);
                dispatch("ajax/is_available", {}, { root: true });
            })
            .catch(() => {
                dispatch("ajax/is_not_available", {}, { root: true });
            });
    },
};

const getters = {
    maintenance_list: (state) => {
        return state.maintenance_list;
    },
    count_items: (state) => {
        return state.maintenance_list.length;
    },
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
};
