<template>
    <div id="history-timeline" class="app-container">
        <div class="width-100 max-700 mt-6 timeline" v-if="history.length > 0">
            <template v-for="(step, index) in history">

                <!-- DATE CARD -->
                <div v-if="isDisplayDateCard(step, index)" class="step-wrapper mb-4" :key="`${step.uid}-date`">
                    <div class="timeline-step-date-card">
                        <span>{{ $t('maintenance_history.date', {date: changeFormatDate(step.date_change, 'DD MMMM YYYY')}) }}</span>
                    </div>
                </div>

                <!-- STEP CARD -->
                <div class="step-wrapper mb-4" :key="`${step.uid}-step`">
                    <!-- LEFT ICON -->
                    <v-btn fab :color="step.state.color" small elevation="0" class="unsolicited-btn">
                        <v-icon class="fa white--text little-icon" :class="step.state.icon" small/>
                    </v-btn>

                    <v-card class="width-100 pa-4" elevation="1">
                        <!-- CARD HEADER -->
                        <div class="d-flex justify-space-between align-center font-14 timeline-card-header">
                             <span>
                                 {{ title(step) }}
                                <v-icon color="red" v-if="step.main_state === 2 && step.secondary_state === 1">
                                    mdi-alert
                                </v-icon>
                            </span>

                            <div class="d-flex align-center">
                                <span
                                    v-if="step.initials && step.badge_color"
                                    :style="`
                                        background-color: ${step.badge_color};
                                        color:  ${whiteOrBlack(step.badge_color)};
                                        font-size: ${step.initials.length >= 3 ? '12px' : '16px'}
                                        `"
                                    class="badge-user"
                                >
                                    {{ step.initials }}
                                </span>
                                <span v-else class="font-weight-bold">{{ $t('global.automatic_action') }}</span>
                                <v-icon class="mr-1 ml-2">mdi-clock-time-nine-outline</v-icon>
                                {{ dateFrom(step.date_change) }}
                            </div>
                        </div>

                        <!-- CARD CONTENT -->
                        <v-divider v-if="step.files || step.comment || index === index_provider_display" class="mt-2"/>
                        <!-- PROVIDER -->
                        <div v-if="index === index_provider_display">
                            <p class="grey--text font-14 mt-2 mb-0">
                                {{ $t('maintenance_history.provider_selected') }} :
                                <span class="font-weight-bold black--text">{{ step.provider_name }}</span>
                            </p>
                            <p class="grey--text font-14 mt-2 mb-0">
                                {{ $t('maintenance_history.type_form') }} :
                                <span class="font-weight-bold black--text">{{ step.form_name }}</span>
                            </p>
                        </div>

                        <!-- COMMENT -->
                        <div v-if="step.comment">
                            <p class="grey--text font-14 mt-2 mb-0" v-if="step.comment">
                                {{ $t('maintenance_history.comment') }} :<br>
                                <span class="font-weight-bold black--text multiple-lines" v-html="step.comment"></span>
                            </p>
                        </div>

                        <!-- FILES -->
                        <div v-if="step.files">
                            <!-- FILE -->
                            <p v-if="step.files.file !== undefined" class="grey--text font-14 mt-2 mb-0">
                                {{ $t('maintenance_history.intervention_report') }} :
                                <a :href="fileUrl(step.files.file)" target="_blank">{{ $t('maintenance_history.download_report') }}</a>
                            </p>

                            <!-- PDFS -->
                            <div v-if="step.files.pdfs" class="grey--text font-14 mt-2 mb-0">
                                <span v-for="(pdf, index) in step.files.pdfs" :key="index">
                                    <v-btn
                                        :href="fileUrl('/doc/pdf/'+pdf)"
                                        target="_blank"
                                        color="primary"
                                        class="mr-5"
                                        small
                                        rounded
                                    >
                                        <v-icon left dark>
                                            mdi-paperclip
                                        </v-icon>
                                        Document {{index + 1}}
                                    </v-btn>
                                </span>

                            </div>

                            <!-- ARCHIVE -->
                            <p v-if="step.files.archive !== undefined" class="grey--text font-14 mt-2 mb-0">
                                {{ $t('maintenance_history.archive') }} :
                                <a :href="fileUrl(step.files.archive)" target="_blank">{{ $t('maintenance_history.download_archive') }}</a>
                            </p>

                            <!-- IMAGE -->
                            <div v-if="step.files.images">
                                <div v-for="(image, index) in step.files.images" :key="index" class="image-container">
                                    <v-img class="image-preview pointer" @click="openDialogImage(image)" contain :src="displayImageInComment(image)"/>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </div>
            </template>
        </div>
        <template v-else>
            <br/>
            <h2>{{ $t("maintenance_history.no_history") }}</h2>
        </template>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "HistoryTimeline",
    data() {
        return {
            index_provider_display: 0
        }
    },
    created() {
        if (this.history[this.history.length - 1].main_state === 1 && this.history[this.history.length - 2].main_state === 2) {
            this.index_provider_display = this.history.length - 2;
        } else if (this.history[this.history.length - 1].main_state === 2) {
            this.index_provider_display = this.history.length - 1;
        }
        moment.locale(this.language);
    },
    computed: {
        history() {
            return this.$store.getters["maintenance/get"].history;
        }
    },
    methods: {
        isDisplayDateCard(step, index) {
            if (index === 0) return true;
            return moment(step.date_change).format("DD MMMM YYYY") !== moment(this.history[index - 1].date_change).format("DD MMMM YYYY");
        },

        fileUrl(file) {
            return `${process.env.VUE_APP_FOURDATA_API_BASE_URL}/api/v1/maintenance/file${file}`;
        },

        changeFormatDate(date, format) {
            return moment(date).format(format);
        },

        title(step) {
            let title_key = '';
            switch (step.main_state) {
                case 1:
                    title_key = 'maintenance_pending_provider';
                    break;
                case 2:
                    if (step.secondary_state === 1) {
                        title_key = 'maintenance_to_do_late';
                    } else {
                        title_key = 'maintenance_to_do';
                    }
                    break;
                case 3:
                    title_key = 'maintenance_done';
                    break;
                case 4:
                    title_key = 'maintenance_ended';
                    break;
                case 5:
                    title_key = 'maintenance_cancel';
                    break;
                default:
                    title_key = 'maintenance_to_do';
            }

            return this.$t(`global.${title_key}`);
        },

        dateFrom(date) {
            return moment(date).from();
        },

        whiteOrBlack(color) {
            // Variables for red, green, blue values
            var r, g, b, hsp;
            // Check the format of the color, HEX or RGB?
            if (color.match(/^rgb/)) {
                // If HEX --> store the red, green, blue values in separate variables
                color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
                r = color[1];
                g = color[2];
                b = color[3];
            } else {
                // If RGB --> Convert it to HEX: http://gist.github.com/983661
                color = +("0x" + color.slice(1).replace(
                    color.length < 5 && /./g, '$&$&'));
                r = color >> 16;
                g = color >> 8 & 255;
                b = color & 255;
            }
            // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
            hsp = 0.299 * r + 0.587 * g + 0.114 * b;

            // Using the HSP value, determine whether the color is light or dark
            if (hsp > 127.5) {
                return 'black';
            } else {
                return 'white';
            }
        }
    },
};
</script>

<style scoped>
.timeline {
    position: relative;
    height: 100%;
}

.unsolicited-btn {
    pointer-events: none;
}

.badge-user {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    font-weight: 500;
    line-height: 1px;
}

.timeline-step-date-card {
    background-color: #dbdbdc;
    padding: 10px 15px;
    width: fit-content;
    border-radius: 5px;
    clip-path: polygon(0% 0, 100% 0, 100% 0%, 100% 100%, 100% 100%, 0% 100%, 0 100%, 0 0%);
}

.step-wrapper {
    position: relative;
    display: flex;
    gap: 10px;
}

.timeline .step-wrapper:not(:last-child)::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    left: 18px;
    top: 16px;
    background: lightgray;
}

@media screen and (max-width: 500px) {
    .timeline-card-header {
        flex-direction: column;
    }
}
</style>