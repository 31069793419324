<!-- TODO Find a better solution. -->
<template>
    <div id="unauthorized" class="app-container justify-center">
        <v-img src="/img/error403.png" max-width="100px" max-height="100px" alt="" />
        <h2 class="unauthorized-color mt-10">Erreur 403</h2>
        <h3 class="unauthorized-color">Accès refusé</h3>
        <p class="unauthorized-color text-center">Vous n'avez pas la permission d'accéder à cette page.</p>
    </div>
</template>

<style>
.unauthorized-color {
    color: #4c545f;
}
</style>

<script>
export default {
    name: "Unauthorized",
};
</script>
