const state = () => ({
    /** Document to be displayed in the DocumentPreview component. */
    document: {
        /** Url of the document to be displayed. */
        src: "",
        /** Page to be displayed. */
        page: 1,
        /** Orientation of the document. */
        rotate: 0,
        /** Boolean to know whether to open the modal or not. */
        dialog_pdf_preview: false,
    },
});

const mutations = {
    SET_SRC(state, src): void {
        state.document.src = src;
    },
    SET_PAGE(state, page_numner) {
        state.document.page = page_numner;
    },
    NEXT(state): void {
        state.document.page++;
    },
    PREVIOUS(state): void {
        state.document.page--;
    },
    SET_ROTATION(state, rotation) {
        state.document.rotate = rotation;
    },
    ROTATE_LEFT(state): void {
        state.document.rotate -= 90;
    },
    ROTATE_RIGHT(state): void {
        state.document.rotate += 90;
    },
    SHOW(state): void {
        state.document.dialog_pdf_preview = true;
    },
    HIDE(state): void {
        state.document.dialog_pdf_preview = false;
    },
};

const actions = {
    show({ commit }, payload): void {
        commit("SET_SRC", payload.src);
        commit("SET_PAGE", 1);
        commit("SET_ROTATION", 0);
        commit("SHOW");
    },
    hide({ commit }): void {
        commit("HIDE");
    },
    previous({ commit }): void {
        commit("PREVIOUS");
    },
    next({ commit }): void {
        commit("NEXT");
    },
};

const getters = {
    show: (state) => state.document.dialog_pdf_preview,
    src: (state) => state.document.src,
    page: (state) => state.document.page,
    rotation: (state) => state.document.rotate,
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
};
