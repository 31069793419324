<template>
  <v-card class="mt-5">
    <AppVCardTitleWithButton label="Contrôle distributeur"
                             image_url="/img/implementedForms/62b97ed722d02/distrubutorControl.png"/>
    <AppCheckboxWithConformityForm v-model="value.gunConditionCheck" label="Contrôle état pistolet *" :required="true"/>
    <AppCheckboxWithConformityForm v-model="value.flexibleCheck" :with-date="true"
                                   label="Contrôle état flexible avec date de validité (valable 6 ans), changement si nécessaire (Présence craquelures, trace de fuites, défaut d’étanchéité) *"
                                   :required="true"/>
    <AppCheckboxWithButton v-model='value.filterReplacement'
                           label='Remplacement du filtre existant par un EPZ75 100 microns, si le filtre en place est conforme, le nettoyer. *'
                           image_url='/img/implementedForms/62b97ed722d02/replaceFilters.png' :required="true"/>

    <AppRadioYesNoBoolean v-model="value.filterReplacement" no_label="Non Applicable"
                        label="Contrôle du filtre à cartouche du distributeur *"
                        image_url='/img/implementedForms/62b97ed722d02/cartridgFilterControl.png'></AppRadioYesNoBoolean>

    <AppCartridgeFilterControl v-model="value.cartridgeFilterControl"/>
    <AppCheckboxWithButton v-model='value.filterBypassOpeningControl'
                           label='Contrôle de la bonne fermeture du bypass du filtre sur le distributeur *'
                           image_url='/img/implementedForms/62b97ed722d02/by-pass.png' :required="true"/>
    <AppRadioYesNo v-model="value.isValveSealed" label="La vanne de bypass est elle bien plombée"/>
    <v-card-text class="pt-0 pb-0">
      Prendre en photo*
      <AppImageInput v-model="image" placeholder="Ajouter une image" :rules="[v => !!v]"/>
    </v-card-text>
    <AppVCardTitle label="Contrôle de la tension de la courroie de groupe"/>
    <AppCheckbox v-model='value.switchOffMotor' label='Couper l’alimentation moteur (disjoncteur général) *'
                 :required="true"/>
    <AppCheckboxWithButton v-model='value.tensionControl' label='Contrôle de la tension *'
                           image_url='/img/implementedForms/62b97ed722d02/tensionControl.png' :required="true"/>
    <AppRadioYesNo v-if="value.tensionControl" v-model='value.resumptionOfTension' label="Reprise de la tension"/>
    <AppRadioYesNo v-if="value.tensionControl" v-model='value.changingBelt' label="Changement de la courroie"/>
    <AppCheckbox v-model='value.cleaning' label='Nettoyage des éléments de carrosseries distributeur *'
                 :required="true"/>
  </v-card>
</template>

<script>
import AppCheckbox from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/Checkbox";
import AppCheckboxWithButton
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/CheckboxWithButton";
import AppCheckboxWithConformityForm
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/CheckboxWithConformityForm";
import AppRadioYesNo from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNo";
import AppVCardTitleWithButton
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VCardTitleWithButton";
import AppVCardTitle from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/VCardTitle";
import AppCartridgeFilterControl
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/CartridgeFilterControl.vue";
import AppImageInput from "@/components/App/ImageInput.vue";
import AppRadioYesNoBoolean
  from "@/components/MaintenanceViewReport/ImplementedForms/Oleo100Preventive/App/RadioYesNoBoolean.vue";

export default {
  name: "ControlOfTheDistributor",
  components: {
    AppRadioYesNoBoolean,
    AppVCardTitle,
    AppVCardTitleWithButton,
    AppCheckbox,
    AppCheckboxWithButton,
    AppCheckboxWithConformityForm,
    AppRadioYesNo,
    AppCartridgeFilterControl,
    AppImageInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: null,
    };
  },
  watch: {
    image(newValue) {
      this.value.valvePicture = newValue
    },
  },
};
</script>
