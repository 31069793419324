<template>
    <v-app-bar color="white" height="128px" app v-if="display">
        <v-img alt="Oleo 100 icon" contain class="hidden-sm-and-down position-absolute" src="/img/pro/14/logo.png" width="75" height="100%" />
        <GoBackButton v-show="display_go_back_button" />
        <div class="width-100 justify-center">
            <v-img alt="Vuetify Name" contain max-width="75" class="hidden-md-and-up ma-auto" src="/img/pro/14/logo.png" width="50" />
            <div class="d-flex flex-row justify-center title-page">
                <h1 class="text-center mt-auto mb-auto">
                    {{ $t(appbar_title) }}
                </h1>
            </div>
        </div>
    </v-app-bar>
</template>

<script>
import { routes } from "@/models/Pages";
import GoBackButton from "@/components/App/GoBackButton";

export default {
    name: "MainAppBar",
    components: {
        GoBackButton,
    },
    computed: {
        display() {
            return routes[this.$route.name].appbar !== null;
        },
        display_go_back_button() {
            let display = false;
            let appbar_data = routes[this.$route.name].appbar;

            if (appbar_data.go_back !== null) {
                if (appbar_data.go_back.access.includes(this.$store.getters["authentication/access_level"])) {
                    display = true;
                }
            }

            return display;
        },
        appbar_title() {
            return routes[this.$route.name].appbar.title;
        },
    },
};
</script>
