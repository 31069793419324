<template>
    <v-card-text>
        <v-text-field :label="label" :hint="hint" type="number" v-model.lazy="val" persistent-hint filled @change="calculateHeight" @keydown.enter.stop.prevent  :rules="[v => v!=='']"/>

        <div v-if="displayValidity">
            <v-row class="pa-4" justify="space-between" align="center">
                <p class="font-16">
                    <span class="font-weight-bold">Jauge Fuel IT</span><br />
                    {{ valueName }}* : <span class="font-weight-bold">{{ expectedMeasure }}{{ unity }}</span>
                </p>
                <v-chip v-if='timestamp !== 0' outlined>{{ il_y_a }}</v-chip>
            </v-row>
            <p class="text-right mb-0">* Donnée provenant de FUEL IT.</p>
            <v-alert shaped text :type="validInput">{{ valueName }} {{ valueCompliance }}</v-alert>
        </div>
    </v-card-text>
</template>

<script>
import moment from "moment";

export default {
    name: "AppGaugingCheck",
    props: {
        label: {
            type: String,
            required: false,
            default: "Jaugeage manuel de la cuve avec mètre ruban *",
        },
        hint: {
            type: String,
            required: false,
            default: "Renseigner en litre ou cm",
        },
        "expected-measure": {
            type: Number,
            required: true,
        },
        timestamp: {
            type: Number,
            required: true,
        },
        unity: {
            type: String,
            required: false,
            default: "cm",
        },
        valueName: {
            type: String,
            required: false,
            default: "Hauteur de liquide",
        },
        offset: {
            type: Number,
            required: false,
            default: 3,
        },
    },
    data() {
        return {
            val: "",
            displayValidity: false,
        };
    },
    computed: {
        il_y_a() {
            moment.locale("fr");
            return moment.unix(this.timestamp).fromNow();
        },
        batteryColor() {
            if (this.battery > 70) return "success--text";
            if (this.battery > 30) return "warning--text";
            return "error--text";
        },
        isValidValue() {
            return this.expectedMeasure + this.offset + this.expectedMeasure * 0.1 > this.val && this.val > this.expectedMeasure - this.offset - this.expectedMeasure * 0.1;
        },
        validInput() {
            if (this.isValidValue) return "success";
            return "warning";
        },
        valueCompliance() {
            if (this.isValidValue) return "cohérente";
            return "incohérente";
        },
    },
    methods: {
        calculateHeight() {
            this.displayValidity = this.val >= 0 && this.val !== '';
            this.$emit("input", {
                value: parseFloat(this.val),
                expected: this.expectedMeasure,
                valid: this.isValidValue,
            });
        },
    },
};
</script>
