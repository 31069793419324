<template>
    <v-app>
        <v-main v-if="this.$store.getters['authentication/access']">
            <MainAppBar />
            <div class="maintenance-app">
                <router-view v-if="this.$store.getters['ajax/available'] === 'YES'" />
                <Error500 v-else-if="this.$store.getters['ajax/available'] === 'NO'" />
                <Spinner v-else />
            </div>
        </v-main>
        <v-main v-else-if="this.$store.getters['authentication/access_level'] === 'UNDEFINED'">
            <Spinner />
        </v-main>
        <v-main v-else>
            <Unauthorized />
        </v-main>
        <Snackbar />
    </v-app>
</template>

<script>
import Vue from "vue";
import "./css/app.scss";
import Spinner from "@/components/App/Spinner.vue";
import Snackbar from "@/components/App/Snackbar.vue";
import Error500 from "@/components/App/Error500.vue";
import MainAppBar from "@/components/App/MainAppBar.vue";
import Unauthorized from "@/views/Unauthorized.vue";

export default Vue.extend({
    name: "App",
    components: {Spinner, Snackbar, Error500, MainAppBar, Unauthorized},
    data() {
        return {
            registration: null,
        }
    },
    created() {
        document.addEventListener('serviceWorkerUpdateEvent', this.updateAvailable, {once: true})
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.reload();
        });
    },
    methods: {
        updateAvailable(event) {
            this.registration = event.detail;
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({type: 'SKIP_WAITING'});
            }
        },
    }
});
</script>
