<template>
    <div class="app-container">
        <div ref="comments" class="app-container flex-column-reverse" style="margin-bottom: 110px">
            <div v-if="comments.length === 0">
                <p class="mt-5 color-gray-darker">{{ $t("maintenance_comment.no_comment") }}</p>
            </div>
            <CommentCard v-for="comment in comments" :key="comment.uid" v-bind:comment="comment" v-else />
        </div>
        <SendComment />
        <ImageViewDialog />
    </div>
</template>

<script>
import CommentCard from "@/components/MaintenanceViewComments/CommentCard";
import SendComment from "@/components/MaintenanceViewComments/SendComment";
import ImageViewDialog from "@/components/MaintenanceViewComments/ImageViewDialog";

export default {
    name: "MaintenanceViewComments",
    components: {
        CommentCard,
        SendComment,
        ImageViewDialog,
    },
    mounted() {
        this.$refs.comments.scrollIntoView({ behavior: "smooth" })
    },
    computed: {
        comments() {
            return this.$store.getters["maintenance/getComments"];
        },
    },
};
</script>
