<template>
    <div>
        <ImagesPreview v-bind:images="images" />
        <div ref="image_preview" class="image-preview-container"></div>
        <v-footer fixed padless color="white" width="100%">
            <v-col class="d-flex flex-column" cols="12">
                <span v-if="images.length !== 0" class="ma-auto color-gray-darker">
                    {{ $t("maintenance_comment.selected_images", { number: images.length }) }}
                    <strong @click="images = []" class="clear-images font-italic">{{ $t("maintenance_comment.clear_images") }}</strong>
                </span>

                <v-file-input v-model="uploaded_images" multiple style="display: none" ref="image_input" accept=".png, .jpg, .jpeg" @change="displayImagesPreview" />
                <v-text-field
                    v-model="new_comment"
                    :label="$t('maintenance_comment.add_comment')"
                    append-icon="mdi-paperclip"
                    append-outer-icon="mdi-send"
                    class="add-comment ma-auto"
                    @click:append="append"
                    @click:append-outer="sendComment"
                    :loading="loading"
                    :disabled="loading"
                />
            </v-col>
        </v-footer>
    </div>
</template>

<script>
import ImagesPreview from "@/components/MaintenanceViewComments/ImagesPreview";
import MaintenanceRepository from "@/repositories/MaintenanceRepository";
import imageCompression from "browser-image-compression";

export default {
    name: "SendComment",
    components: {
        ImagesPreview,
    },
    data() {
        return {
            new_comment: "",
            loading: false,
            images: [],
            uploaded_images: [],
        };
    },
    methods: {
        append() {
            this.$refs.image_input.$refs.input.click();
        },
        scrollBottom() {
            if (this.images.length !== 0) {
                let el = this.$refs.image_preview;
                setTimeout(() => el.scrollIntoView({ behavior: "smooth" }), 500);
            }
        },
        removeImage(index) {
            this.images.splice(index, 1);
        },
        sendComment() {
            if (this.new_comment !== "" || this.images.length > 0) {
                this.loading = true;
                let data = new FormData();
                data.append("comment", this.new_comment || "");
                let numberOfImages = 0;
                for (let i = 1; i <= this.images.length; i++) {
                    data.append("image" + i, this.images[i - 1]);
                    numberOfImages++;
                }
                data.append("numberOfImages", numberOfImages);
                MaintenanceRepository.addComment(data).then((response) => {
                    if (response.status === 200) {
                        this.new_comment = "";
                        this.images = [];
                        this.$store.dispatch("maintenance/load");
                        if (this.$store.getters["authentication/access_maintenance_list"]) {
                            this.$store.dispatch("maintenance_list/load");
                        }

                        this.$store.dispatch("snackbar/show", {
                            snackbar_text: "Commentaire ajouté avec succès",
                            snackbar_type: "success",
                        });
                    } else {
                        this.$store.dispatch("snackbar/show", {
                            snackbar_text: response.data.data.error,
                            snackbar_type: "error",
                        });
                    }
                });

                this.loading = false;
            }
        },
        displayImagesPreview(uploaded_images) {
            for (const index in uploaded_images) {
                let imageFile = uploaded_images[index];

                const options = {
                    maxSizeMB: 0.03,
                    maxWidthOrHeight: 1080,
                    useWebWorker: true,
                };

                try {
                    imageCompression(imageFile, options).then(function (compressedFile) {
                        let file = new File([compressedFile], compressedFile.name, {
                            type: compressedFile.type,
                            lastModified: new Date().getTime(),
                        });

                        let container = new DataTransfer();

                        container.items.add(file);

                        uploaded_images[index] = container.files[0];
                    });

                    this.images = uploaded_images;

                    this.$store.dispatch("snackbar/show", {
                        snackbar_text: this.$tc('maintenance_comment.image_added', this.images.length),
                        snackbar_type: "success",
                    });
                } catch (error) {
                    this.$store.dispatch("snackbar/show", {
                        snackbar_text: "Erreur lors de la réduction de la taille des images ...",
                        snackbar_type: "error",
                    });
                }
            }

            this.scrollBottom();
        },
    },
};
</script>

<style scoped>
.image-preview-container {
    position: absolute; 
    bottom: 0;
}
</style>