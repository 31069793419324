import MaintenanceRepository from "@/repositories/MaintenanceRepository";

const state = () => ({
    /** Maintenance to be displayed to the user. */
    maintenance: {
        id_maintenance: 0,
        uid: "",
        title: "",
        type: "",
        date_maintenance: "",
        client: {
            name: "",
        },
        state: {
            main_state: 0,
            secondary_state: 0,
        },
        address: {
            numero: "",
            adresse: "",
            code_postal: "",
            ville: "",
        },
        contact: {
            name: "",
            phone: "",
            mail: "",
        },
        comment: "",
        comments: [],
        history: [],
    },
});

const mutations = {
    SET_MAINTENANCE(state, maintenance) {
        state.maintenance = maintenance;
    },
};

const actions = {
    load({ commit, dispatch }) {
        dispatch("ajax/in_recovery", { action: "Récupération de la maintenance selectionné ..." }, { root: true });
        MaintenanceRepository.get()
            .then((res) => {
                commit("SET_MAINTENANCE", res.data.data[0]);
                dispatch("ajax/is_available", {}, { root: true });
            })
            .catch(() => {
                dispatch("ajax/is_not_available", {}, { root: true });
            });
    },
    setMaintenance({ commit }, payload) {
        commit("SET_MAINTENANCE", payload.maintenance);
    },
};

const getters = {
    get: (state) => state.maintenance,
    getComments: (state) => state.maintenance.comments,
};

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
};
